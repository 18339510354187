// import React,{useContext} from 'react'
// import AuthContext from '../context/AuthContext'

// const Header = () => {
  
//   const {user,logoutUser} = useContext(AuthContext)

  
//   return user&&user["policy_check"]?
//   (<div className='app-header'>
//     <p>Hello, {user?.username} !!!</p>
    
//     <p id='logout' onClick={logoutUser} >Logout</p>
//   </div>)
//   :null
// }

// export default Header
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom'; // Nếu bạn sử dụng react-router để điều hướng
import AuthContext from '../context/AuthContext';

const Header = () => {
  const { user, logoutUser } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return user && user['policy_check'] ? (
    <div className='app-header'>
      <div className='header-content'>
        <div className='left-section'>
          <div className='menu-icon' onClick={toggleSidebar}>
            &#9776; {/* Biểu tượng menu */}
          </div>
          <nav className='nav-menu'>
            <Link to='/' >Home</Link>
            <Link to='https://blog.code2trade.top/indicators' target='_blank' rel='noopener noreferrer'>Merge Indicator Tool</Link>
            <Link to='https://blog.code2trade.top/#contact' target='_blank' rel='noopener noreferrer'>Contact</Link>
            <Link to='https://blog.code2trade.top/#blog' target='_blank' rel='noopener noreferrer'>Blog</Link>
          </nav>
        </div>
        <div className='user-info'>
          <p>Hello, {user?.username}!</p>
          <p id='logout' onClick={logoutUser}>
            Logout
          </p>
        </div>
      </div>
      {/* Sidebar nav menu cho mobile */}
      <nav className={`sidebar-nav-menu ${isSidebarOpen ? 'open' : ''}`}>
        <div className='close-icon' onClick={closeSidebar}>
          &times; {/* Biểu tượng đóng */}
        </div>
        <Link to='/' onClick={closeSidebar}>
          Home
        </Link>
        <Link to='/merge-indicator-tool' target='_blank' rel='noopener noreferrer' onClick={closeSidebar}>
          Merge Indicator Tool
        </Link>
        <Link to='/contact' target='_blank' rel='noopener noreferrer' onClick={closeSidebar}>
          Contact
        </Link>
        <Link to='/blog' target='_blank' rel='noopener noreferrer' onClick={closeSidebar}>
          Blog
        </Link>
      </nav>
    </div>
  ) : null;
};

export default Header;
